import { createStore, combineReducers } from 'redux';

// redux reducers
import account from './reducers/account';
import accounts from './reducers/accounts';
import consortium from './reducers/consortium';
import consortiums from './reducers/consortiums';
import group from './reducers/group';
import groups from './reducers/groups';
import outcomes from './reducers/outcomes';
import users from './reducers/users';
import favorites from './reducers/favorites';
import featureFlags from './reducers/feature-flags';
import localStorage from './reducers/local-storage';

// uniflow stores
import CoursesStore from './stores/courses';
import ImageStore from './stores/images';
import LicensesStore from './stores/licenses';
import NotificationCenterStore from './stores/notification-center';
import ResourceStore from './stores/resource';
import ResultPathStore from './stores/result-path';
import ResultsStore from './stores/results';
import RouterStore from './stores/router';
import SearchStore from './stores/search';
import SessionStore from './stores/session';
import UpdatesStore from './stores/updates';

const appReducer = combineReducers({
  accounts,
  account,
  consortium,
  consortiums,
  group,
  groups,
  outcomes,
  users,
  favorites,
  featureFlags,
  localStorage,
});

const addDevTool = process.env.NODE_ENV === 'development' &&
  window.__REDUX_DEVTOOLS_EXTENSION__;
const store = createStore(
  appReducer,
  undefined, /* initial state */
  addDevTool ? window.__REDUX_DEVTOOLS_EXTENSION__() : undefined
);
const reduxSubscribe = store.subscribe;
const reduxGetState = store.getState;

export function getState () {
  return {
    courses: CoursesStore.state,
    images: ImageStore.state,
    licenses: LicensesStore.state,
    notificationCenter: NotificationCenterStore.state,
    resource: ResourceStore.state,
    resultPath: ResultPathStore.state,
    results: ResultsStore.state,
    router: RouterStore.state,
    search: SearchStore.state,
    session: SessionStore.state,
    updates: UpdatesStore.state,
    ...reduxGetState()
  };
}

export function subscribe (listener) {
  CoursesStore.on('change', listener);
  ImageStore.on('change', listener);
  LicensesStore.on('change', listener);
  NotificationCenterStore.on('change', listener);
  ResourceStore.on('change', listener);
  ResultPathStore.on('change', listener);
  ResultsStore.on('change', listener);
  RouterStore.on('change', listener);
  SearchStore.on('change', listener);
  SessionStore.on('change', listener);
  UpdatesStore.on('change', listener);
  const reduxUnsubscribe = reduxSubscribe(listener);

  return function unsubscribe () {
    CoursesStore.removeListener('change', listener);
    ImageStore.removeListener('change', listener);
    LicensesStore.removeListener('change', listener);
    NotificationCenterStore.removeListener('change', listener);
    ResourceStore.removeListener('change', listener);
    ResultPathStore.removeListener('change', listener);
    ResultsStore.removeListener('change', listener);
    RouterStore.removeListener('change', listener);
    SearchStore.removeListener('change', listener);
    SessionStore.removeListener('change', listener);
    UpdatesStore.removeListener('change', listener);
    reduxUnsubscribe();
  };
}

export const dispatch = store.dispatch;
