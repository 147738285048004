import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import * as searchPaths from '../../constants/search-paths';
import * as paginatedPageSizes from '../../constants/paginated-page-sizes';
import { createFetchResults } from '../../common/util/create-fetch-results';

// Components
import SearchObjects from '../search/search-objects';
import NoResult from '../common/no-result';
import starUrl from '../../../assets/svg/star-flag.svg';
import Loading from '../common/loading';

// Stores
import ResultsStore from '../../stores/results';

export default class FavoritesHandler extends React.Component {
  static displayName = 'FavoritesHandler'
  static isResultView = true

  static propTypes = {
    session: PropTypes.object,
    favorites: PropTypes.object
  }

  render () {
    const { session, account, favorites, results } = this.props;

    if (!favorites.listIsLoaded) {
      return (
        <div className="AppHandler--loading">
          <Loading />
        </div>
      );
    }

    const hasFavorites = Boolean(results.total);
    const noResultsMessage = hasFavorites
      ? formatMessage('No Results Found')
      : formatMessage('Currently No Favorites');

    const resultsPerPage = paginatedPageSizes.FAVORITES_PAGE_SIZE;
    const fetchResults = createFetchResults(searchPaths.RESOURCES, resultsPerPage);

    return (
      <SearchObjects
        baseParams={{
          onlyFavorites: true
        }}
        fetchResults={fetchResults}
        resultsPerPage={resultsPerPage}
        queryArialLabel={formatMessage(
          'Search My Favorited Resources for course resources by keyword, author, institution, or title'
        )}
        isLoading={ResultsStore.showLoading()}
        showFavoriteOptions
        emptyMessage={
          <NoResult
            imageUrl={starUrl}
            imageSize={70}
            title={noResultsMessage}
            message={
              !hasFavorites &&
              formatMessage(
                'Add favorites from Commons by clicking the "Add to Favorites" link in the Resource details page.'
              )
            }
          />
        }
        canShowPublic={
          account.settings.canShowPublic && session.sid && !session.publicOnly
        }
        {...this.props}
      />
    );
  }
}
