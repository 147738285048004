import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import formatMessage from 'format-message';
import { ProgressBar } from '@instructure/ui-progress';

import ResourceTypeIcon from '../../icons/resource-type-progress';
import { resourceTypes } from '../../../common/app-constants';
import DeadLaptop from '../../errors/dead-laptop';
import Button from '../../common/button';

const lerp = (min, max, percent) => min + (max - min) * (percent / 100);
const getProgressPercent = (progressObject) => {
  if (!progressObject) {
    return 0;
  }
  const { phase, state, progress } = progressObject;
  if (phase === 'starting_canvas_export') {
    return 10;
  }
  if (phase === 'polling_canvas_export') {
    return lerp(10, 50, progress);
  }
  if (state === 'not_found') {
    return 100;
  }
  return lerp(50, 97, progress);
};

const useFakeProgressPercent = (progressPercent) => {
  const [oldProgressPercent, setOldProgressPercent] = useState(0);
  const [progressPercentToShow, setProgressPercentToShow] = useState(0);

  useEffect(() => {
    const runTime = 5000;
    const desiredFPS = 10;
    const slices = 5000 / (1000 / desiredFPS);
    let currentIndex = 0;
    const cycleTime = runTime / slices;

    const interval = setInterval(() => {
      const percentOfCurrentCycle = ((currentIndex * cycleTime) / runTime) * 100;
      currentIndex++;

      const percent = lerp(oldProgressPercent, progressPercent, percentOfCurrentCycle);
      setProgressPercentToShow(percent);

      if (percentOfCurrentCycle >= 100) {
        clearInterval(interval);
      }
    }, cycleTime);

    setOldProgressPercent(progressPercent);

    return () => clearInterval(interval);
  }, [progressPercent]);

  return progressPercentToShow;
};

const ProgressThumbnail = ({ resourceType, progress, onFinish }) => {
  const resourceTypeConstant = resourceTypes[resourceType] || {};
  const percent = useFakeProgressPercent(getProgressPercent(progress));

  useEffect(() => {
    if (percent >= 100) {
      onFinish();
    }
  }, [percent]);

  return (
    <div className="SearchResultThumbnail-image">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#8B969E',
          backgroundColor: '#FFFFFF',
          height: '100%',
          textAlign: 'center'
        }}
      >
        <div>
          <ResourceTypeIcon
            ghost
            aria-label={resourceTypeConstant.singularName}
            type={resourceType || 'course'}
            iconSize="medium"
          />
          <div
            style={{
              marginTop: 16,
              textTransform: 'uppercase'
            }}
          >
            {formatMessage('Sharing...')}
          </div>
        </div>
      </div>
      <ProgressBar
        screenReaderLabel={formatMessage('Share in progress')}
        valueMax={100}
        valueNow={percent}
        size="x-small"
      />
    </div>
  );
};

function ErrorPreviewCard ({ resource, retryFailedJob }) {
  const { title } = resource;
  return (
    <div
      className="PreviewCard"
      style={{
        height: 370.95,
        display: 'grid',
        gridTemplateRows: '1fr min-content',
        padding: '8px'
      }}
    >
      <div
        style={{
          textAlign: 'center',
          marginTop: '32px',
          color: '#384A58'
        }}
      >
        <DeadLaptop />
        <div
          style={{
            marginTop: '24px',
            fontSize: '16px'
          }}
        >
          {formatMessage('Failed to share: {title}', {
            title
          })}
        </div>
        <div
          style={{
            marginTop: '12px',
            fontSize: '14px'
          }}
        >
          {formatMessage('We couldn’t process your resource. Please, try again later.')}
        </div>
      </div>
      <div
        style={{
          display: 'grid',
          gridGap: '4px'
        }}
      >
        <div className="FavoriteResourceCard-error-button">
          <Button
            size={Button.size.medium}
            display={Button.display.block}
            textAlign={Button.textAlign.start}
            color={Button.color.primary}
            onClick={() => retryFailedJob()}
          >
            {formatMessage('Retry')}
          </Button>
        </div>
        <div className="FavoriteResourceCard-error-button">
          <Button
            size={Button.size.medium}
            display={Button.display.block}
            textAlign={Button.textAlign.start}
            onClick={() => {}}
          >
            {formatMessage('Dismiss')}
          </Button>
        </div>
      </div>
    </div>
  );
}
const isInErrorState = (progress) => progress && progress.state === 'failed';

export default function PreviewCard (props) {
  const { resource, progress, retryFailedJob, onFinish } = props;

  if (isInErrorState(progress)) {
    return <ErrorPreviewCard retryFailedJob={retryFailedJob} resource={resource} />;
  }

  const previewCardClasses = classnames('PreviewCard');

  const previewWrapperClasses = 'PreviewCard-wrapper';

  const { title, type } = resource;

  return (
    <div
      className={previewCardClasses}
      data-automation={previewCardClasses}
      style={{
        height: 370.95
      }}
    >
      <ProgressThumbnail progress={progress} resourceType={type} onFinish={onFinish} />
      <div className={previewWrapperClasses}>
        <div className="PreviewCard-title-description">
          <h3
            className={`PreviewCard-resourceType font-color type-${type}`}
            data-automation="PreviewCardResourceType"
          >
            {resourceTypes[type].singularName}
          </h3>
          <h2 data-automation="PreviewCardResourceTitle"> {title}</h2>
          <div
            style={{
              marginTop: 15
            }}
          >
            {formatMessage('Processing may take some time depending on the file size of the Common Cartridge.')}
          </div>
        </div>
      </div>
    </div>
  );
}
