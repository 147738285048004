import React, { useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { Modal } from '@instructure/ui-modal';
import { Heading } from '@instructure/ui-heading';
import { CloseButton } from '@instructure/ui-buttons';
import { Text } from '@instructure/ui-text';

import Button from './common/button';

function ConfirmationDialog ({
  label,
  message,
  confirmText,
  cancelText,
  hideCancelButton,
  title,
  onClose
}) {
  const [isOpen, setOpenState] = useState(true);
  let result = false;

  const handleDismiss = useCallback(
    () => {
      setOpenState(false);
      result = false;
    },
    [result],
  );

  const handleSuccess = useCallback(
    () => {
      setOpenState(false);
      result = true;
    },
    [result],
  );

  const handleOnExitedCallback = useCallback(
    () => {
      if (onClose) {
        onClose(result);
        result = false;
      }
    },
    [onClose, result],
  );

  return (
    <Modal
      open={isOpen}
      onExited={handleOnExitedCallback}
      label={label}
      size="small"
      data-automation={`${label}Dialog`}
    >
      {title ? (
        <Modal.Header>
          <CloseButton
            placement="end"
            offset="small"
            data-automation={`${label}CloseButton`}
            onClick={handleDismiss}
            screenReaderLabel={formatMessage('Close')}
          />
          <Heading>{title}</Heading>
        </Modal.Header>
      )
        : null}
      <Modal.Body>
        <Text lineHeight="default">{message}</Text>
      </Modal.Body>
      <Modal.Footer>
        {!hideCancelButton ? (
          <Button
            data-automation={`${label}CancelButton`}
            margin="0 x-small 0 0"
            onClick={handleDismiss}
          >
            {cancelText}
          </Button>
        ) : null}
        <Button
          data-automation={`${label}ConfirmButton`}
          color={Button.color.primary}
          onClick={handleSuccess}
        >
          {confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ConfirmationDialog.propTypes = {
  label: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  hideCancelButton: PropTypes.bool,
  onClose: PropTypes.func
};

ConfirmationDialog.defaultProps = {
  onClose: null,
  hideCancelButton: false,
  confirmText: 'Ok',
  cancelText: 'Cancel',
  title: ''
};

const confirmationDialog = (props) =>
  new Promise((resolve) => {
    const confirmRoot = document.createElement('div');
    const body = document.getElementsByTagName('body')[0];

    body.appendChild(confirmRoot);

    const onClose = (answer) => {
      ReactDOM.unmountComponentAtNode(confirmRoot);
      body.removeChild(confirmRoot);

      resolve(answer);
    };

    ReactDOM.render(
      <ConfirmationDialog onClose={onClose} {...props} label="Confirmation" />,
      confirmRoot
    );
  });

export {
  confirmationDialog,
};
export default ConfirmationDialog;
