import React, { useEffect, useState } from 'react';
import formatMessage from 'format-message';
import Loading from '../../common/loading';
import Link from '../../common/link';

export default function LoadMore (props) {
  const [prevCount, setPrevCount] = useState(props.count);

  useEffect(() => {
    if (prevCount !== 0 && props.count > prevCount && props.focusContainerRef.current) {
      const element = props.focusContainerRef.current.querySelector(
        `li:nth-child(${prevCount + 1}) .lor-result a`
      );
      if (element) {
        element.focus();
      }
    }
    setPrevCount(props.count);
  }, [props.count]);

  const loadMoreCssClass = props.isLoading ? 'load-more-loader-visible' : 'load-more-loader-hidden';

  return (
    <ol className="load-more">
      {props.hasMore && !props.isLoading && (
        <li style={{ listStyle: 'none' }} className="load-more-button">
          <Link
            onClick={props.onLoadMore}
            className="load-more-button-btn"
          >
            {formatMessage('Load more results')}
          </Link>
        </li>
      )}

      {props.count > 0 && props.hasMore && (
        <li
          aria-hidden={!props.isLoading}
          className={`load-more-loader ${loadMoreCssClass}`}
        >
          <Loading />
        </li>
      )}
    </ol>
  );
}
