import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { IconTrashSolid } from '@instructure/ui-icons';
import { IconButton } from '@instructure/ui-buttons';

import { confirmationDialog } from '../confirmation-dialog';
import * as announce from '../../common/announce';

export default class ConfirmAction extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    confirmMessage: PropTypes.string,
    onPerformAction: PropTypes.func.isRequired,
    onConfirmMessage: PropTypes.string,
    actionIcon: PropTypes.node,
    confirmationMessage: PropTypes.string.isRequired,
    confirmationTitle: PropTypes.string.isRequired,
    confirmationTextButton: PropTypes.string.isRequired
  }

  static defaultProps = {
    onConfirmMessage: formatMessage('Deleted'),
    confirmMessage: formatMessage('Delete'),
    actionIcon: <IconTrashSolid color="primary" />,
  }

   openConfirmation = async () => {
     const result = await confirmationDialog({
       message: this.props.confirmationMessage,
       title: this.props.confirmationTitle,
       confirmText: this.props.confirmationTextButton
     });

     if (result) {
       this.doAction();
     }
   }

  doAction = () => {
    this.props.onPerformAction();
    announce.assertively(this.props.onConfirmMessage);
  }

  componentWillUnmount () {
    announce.delayedReset();
  }

  render () {
    return (
      <li className="ConfirmOptions">
        <div className="ConfirmOptions-children">
          {this.props.children}
          <div className="ConfirmOptions-corner">
            <IconButton
              size="small"
              onClick={() => this.openConfirmation()}
              data-automation="DeleteButton"
              withBorder={false}
              screenReaderLabel={this.props.confirmMessage}
            >
              {this.props.actionIcon}
            </IconButton>
          </div>
        </div>
      </li>
    );
  }
}
