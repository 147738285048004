import Uniflow from 'uniflow';

// actions
import SearchActions from '../actions/search';
import RouterActions from '../actions/router';

var SearchStore = Uniflow.createStore({
  state: {
    results: [],
    searchPending: false,
    searchError: null,
    searchSuccessful: false,
    cursor: null,
    lastQuery: {},
    count: -1,
    confirmOnDelete: true,
  },

  hasMore () {
    return !!(
      this.state.cursor &&
      this.state.results.length < this.state.count
    );
  }
});

// pending
SearchActions.on('search-pending', (query) => {
  SearchStore.setState({
    searchPending: true,
    searchError: null,
    searchSuccessful: false,
    cursor: null,
    lastQuery: query
  });
});

// error
SearchActions.on('search-error', (error) => {
  SearchStore.setState({
    searchPending: false,
    searchError: error
  });
});

// success
SearchActions.on('search-successful', (results) => {
  var learningObjects = (results && Array.isArray(results.items)) ? results.items : [];
  var { cursor, count } = results && results.meta;
  results = SearchStore.state.results.slice();

  var isNewSearch = SearchStore.state.lastQuery.where.cursor === 'initial';
  if (isNewSearch) {
    results = learningObjects;
  } else {
    results = results.concat(learningObjects);
  }

  SearchStore.setState({
    searchPending: false,
    searchSuccessful: true,
    results,
    cursor,
    count
  });
});

SearchActions.on('dont-confirm-on-delete', () => {
  SearchStore.setState({
    confirmOnDelete: false
  });
});

RouterActions.on('match', () => {
  if (!SearchStore.state.confirmOnDelete) {
    SearchStore.setState({
      confirmOnDelete: true
    });
  }
});

export default SearchStore;
