import React from 'react';
import classnames from 'classnames';
import formatMessage from 'format-message';
import FocusTrap from 'focus-trap-react';
import { CloseButton } from '@instructure/ui-buttons';

export default class Tray extends React.Component {
  static defaultProps = {
    id: 'Tray',
    show: false
  }

  constructor (props, context) {
    super(props, context);
    this.tray = null;
    this.state = {
      close: false
    };
  }

  closePanel = () => {
    this.tray.addEventListener('animationend', this.handleAnimEnd, false);
    this.setState({ close: true });
  }

  handleAnimEnd = () => {
    this.tray.removeEventListener('animationend', this.handleAnimEnd, false);
    this.props.onClose();
    this.setState({ close: false });
  }

  handleRef = element => {
    this.tray = element;
  }

  render () {
    return (
      <div id={`${this.props.id || 'Tray'}--tray`}>
        {this.props.show && (
          <FocusTrap
            focusTrapOptions={{
              clickOutsideDeactivates: true,
              onDeactivate: this.closePanel
            }}
            active={this.props.show}
          >
            <div
              id={this.props.id || null}
              aria-label={this.props.label || 'Tray'}
              aria-modal
              aria-hidden={!this.props.show}
              role="dialog"
              ref={el => this.handleRef(el)}
              className={classnames(
                'Tray',
                {
                  'Tray--opened': this.props.show && !this.state.close,
                  'Tray--close': this.state.close
                },
                this.props.className || null
              )}
            >
              <div>
                <div className="Tray-title">
                  <CloseButton
                    size="medium"
                    margin="0 x-small 0 0"
                    onClick={this.closePanel}
                    screenReaderLabel={formatMessage('Close')}
                  />
                  <h3>{this.props.label}</h3>
                </div>
                {this.props.children}
              </div>
            </div>
          </FocusTrap>
        )}
      </div>
    );
  }
}
