import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import difference from 'lodash/array/difference';
import pick from 'lodash/object/pick';
import { Checkbox } from '@instructure/ui-checkbox';
import { Text } from '@instructure/ui-text';
import Select from '@instructure/ui-forms/lib/components/Select';
import { AccessibleContent } from '@instructure/ui-a11y/lib';

import { AccountActions, UsersActions } from '../../actions';
import FormGroup from '../common/form-group';
import Warning from '../common/warning';
import NotifyMessage from '../common/notify-message';
import BrandingImageUpload from './branding-image-upload';
import SearchFilters from '../search/search-filters';
import BrandingColorPicker from './branding-color-picker';
import OutcomeAuthoritySelect from '../common/outcome-authority-select';

export default class AccountSettings extends React.PureComponent {
  getError = param => {
    return (this.props.accountState.saveAccountSettingsErrors || []).find(
      error => error.param === param
    );
  }

  handleCuratorSearch = (evt, value) => {
    if (value === '') UsersActions.resetSearchUsers();
    if (value.length >= 3) {
      UsersActions.searchUsers(value);
    }
  }

  handleCuratorSelect = (evt, value) => {
    const curatorList = value.map(user => user.id);
    const accountCurators = this.props.accountState.settings.curators
      ? this.props.accountState.settings.curators.map(({ uuid }) => uuid)
      : [];

    if (curatorList.length > accountCurators.length) {
      const curatorToAdd = difference(curatorList, accountCurators)[0];
      AccountActions.addAccouuntCurators(curatorToAdd);
    } else {
      const itemToRemove = difference(accountCurators, curatorList)[0];
      AccountActions.removeAccouuntCurators(itemToRemove);
    }
  }

  handleDropDownFilter = (options, filterText) => {
    return options.filter(option =>
      option.label.toLowerCase().includes(filterText.toLowerCase())
    );
  }

  handleSelectedOptions = () => {
    return this.props.accountState.settings.curators
      ? this.props.accountState.settings.curators.map(curator => {
        const curatorname = `${curator.firstName || ''} ${curator.lastName ||
            ''}`.trim();
        return {
          label: `${curatorname} ${curator.email}`.trim(),
          value: curator.uuid,
          id: curator.uuid
        };
      })
      : [];
  }

  handleUserOptions = () => {
    return this.props.userState
      ? this.props.userState.items.map(option => {
        return {
          label: `${option.fullName} ${option.email}`.trim(),
          value: option.uuid,
          id: option.uuid
        };
      })
      : [];
  }

  handleSettingsChange = evt => {
    this.saveSettings({
      ...this.props.accountState.settings,
      [evt.target.value]: evt.target.checked
    });
  }

  handleCuratorBannerColorChange = color => {
    this.saveSettings({
      ...this.props.accountState.settings,
      accountCuratedBannerColor: color
    });
  }

  handleSelectStateChange = (_, data) => {
    this.saveSettings({
      ...this.props.accountState.settings,
      stateStandard: data.value
    });
  }

  handleDefaultSearchOptionsChange = (change) => {
    const settings = { ...this.props.accountState.settings };

    settings.defaultSearchFilters = {
      ...settings.defaultSearchFilters,
      ...change
    };

    // clean empty arrays for dynamo:
    settings.defaultSearchFilters = pick(
      settings.defaultSearchFilters,
      Object.keys(settings.defaultSearchFilters).filter(key => {
        return !(
          Array.isArray(settings.defaultSearchFilters[key]) &&
          settings.defaultSearchFilters[key].length === 0
        );
      })
    );

    this.saveSettings(settings);
  }

  saveSettings (settings) {
    AccountActions.changeAccountSettings(settings);
    AccountActions.saveAccountSettings(settings);
  }

  componentWillUnmount = () => {
    AccountActions.clearSaveState();
  }

  handleCuratorBrandingImageUpload = image => {
    AccountActions.uploadBrandingImage(image);
  }

  render = () => {
    const accountSettings = this.props.accountState.settings;
    const hasAccessError = this.getError('isAdmin');
    const settingsError = this.getError('account-settings');
    const outcomesError = this.getError('outcomes-settings');
    const stateStandard = accountSettings.stateStandard;

    const userOptions = this.handleUserOptions();
    const defaultOptions = this.handleSelectedOptions();

    const allowDefaultFilters =
      accountSettings.defaultSearchFilters &&
      accountSettings.defaultSearchFilters.enabled;

    const accountCuratedBannerColor =
      accountSettings.accountCuratedBannerColor || null;

    const accountCuratedBannerImage =
      accountSettings.accountCuratedBannerImage || null;

    return (
      <div>
        {hasAccessError && (
          <NotifyMessage
            key={hasAccessError.msg}
            kind="danger"
            className="SettingsForm-error"
            icon={<Warning width="20px" height="20px" />}
          >
            {hasAccessError.msg}
          </NotifyMessage>
        )}
        <form className="SettingsForm" onSubmit={this.handleSubmit}>
          {!this.props.accountState.settings.publicOnly && (
            <FormGroup
              legend={formatMessage('Content')}
              hasErrors={!!settingsError}
              errorMessage={settingsError && settingsError.msg}
            >
              <div className="settings-holder">
                <Checkbox
                  checked={accountSettings.allowApprovedContent}
                  label={
                    <Text data-automation="AllowApprovedContentToggle">
                      {formatMessage(
                        'Allow Approved Content for {institution}',
                        {
                          institution: this.props.session.accountName
                        }
                      )}
                    </Text>
                  }
                  onChange={this.handleSettingsChange}
                  size="small"
                  value="allowApprovedContent"
                  data-automation="AllowApprovedContentCheckbox"
                  variant="toggle"
                />
                {accountSettings.allowApprovedContent && (
                  <div>
                    <div className="settings-subMenu">
                      <BrandingColorPicker
                        onChange={this.handleCuratorBannerColorChange}
                        accountCuratedBannerColor={accountCuratedBannerColor}
                      />
                    </div>
                    <div className="settings-subMenu">
                      <BrandingImageUpload
                        onChange={this.handleCuratorBrandingImageUpload}
                        accountCuratedBannerImage={accountCuratedBannerImage}
                        accountCuratedBannerColor={accountCuratedBannerColor}
                      />
                    </div>
                    <div
                      className="settings-subMenu"
                      style={{ paddingLeft: '5px', marginTop: '15px' }}
                    >
                      <Checkbox
                        checked={accountSettings.showCuratedResourcesFirst}
                        label={
                          <Text data-automation="PrioritizeApprovedContentToggle">
                            {formatMessage(
                              'Prioritize approved content in search results'
                            )}
                          </Text>
                        }
                        onChange={this.handleSettingsChange}
                        value="showCuratedResourcesFirst"
                        data-automation="PrioritizeApprovedContentCheckbox"
                      />
                    </div>
                    <div
                      className="settings-subMenu"
                      style={{ paddingLeft: '5px' }}
                    >
                      <Select
                        label={
                          <Text>
                            {formatMessage(
                              'Add users to manage approved content'
                            )}
                          </Text>
                        }
                        onChange={this.handleCuratorSelect}
                        onInputChange={this.handleCuratorSearch}
                        selectedOption={defaultOptions}
                        filter={this.handleDropDownFilter}
                        editable
                        multiple
                        formatSelectedOption={tag => (
                          <AccessibleContent
                            alt={formatMessage('Remove {user}', {
                              user: tag.label
                            })}
                          >
                            {tag.label}
                          </AccessibleContent>
                        )}
                      >
                        {userOptions.map((label, index) => (
                          <option key={label.value} value={label.value}>
                            {label.label}
                          </option>
                        ))}
                      </Select>
                    </div>
                  </div>
                )}
              </div>
              <div className="settings-holder">
                <Checkbox
                  checked={accountSettings.canSharePublic}
                  label={
                    <Text data-automation="CanSharePublicToggle">
                      {formatMessage('Allow users to share to public account')}
                    </Text>
                  }
                  onChange={this.handleSettingsChange}
                  size="small"
                  value="canSharePublic"
                  variant="toggle"
                  data-automation="CanSharePublicCheckbox"
                />
              </div>
              <div className="settings-holder">
                <Checkbox
                  checked={accountSettings.canShowPublic}
                  label={
                    <Text data-automation="CanShowPublicToggle">
                      {formatMessage(
                        'Allow users to view publicly shared content'
                      )}
                    </Text>
                  }
                  onChange={this.handleSettingsChange}
                  size="small"
                  value="canShowPublic"
                  variant="toggle"
                  data-automation="CanShowPublicCheckbox"
                />
              </div>
              <div className="settings-holder">
                <Checkbox
                  checked={accountSettings.canShowFeatured}
                  label={
                    <Text data-automation="CanShowFeaturedToggle">
                      {formatMessage('Allow content featured by Instructure')}
                    </Text>
                  }
                  onChange={this.handleSettingsChange}
                  size="small"
                  value="canShowFeatured"
                  variant="toggle"
                  data-automation="CanShowFeaturedCheckbox"
                />
              </div>

              <div className="settings-holder">
                <Checkbox
                  checked={allowDefaultFilters}
                  label={
                    <Text data-automation="AllowDefaultSearchFiltersToggle">
                      {formatMessage('Configure default search filters')}
                    </Text>
                  }
                  onChange={() => {
                    this.handleDefaultSearchOptionsChange({
                      enabled: !allowDefaultFilters
                    });
                  }}
                  size="small"
                  value="allowDefaultSearchFilters"
                  variant="toggle"
                  data-automation="AllowDefaultSearchFiltersCheckbox"
                />
                {allowDefaultFilters && (
                  <div className="settings-subMenu">
                    <SearchFilters
                      {...accountSettings.defaultSearchFilters}
                      account={this.props.session.account}
                      consortiums={this.props.consortiums}
                      onChange={this.handleDefaultSearchOptionsChange}
                      showTypes={false}
                      canShowPublic={accountSettings.canShowPublic}
                      canShowFeatured={accountSettings.canShowFeatured}
                      canShowApprovedContent={
                        accountSettings.allowApprovedContent
                      }
                      checkboxSize="small"
                    />
                  </div>
                )}
              </div>
            </FormGroup>
          )}

          <FormGroup
            legend={formatMessage('Standards & Outcomes')}
            hasErrors={!!outcomesError}
            errorMessage={outcomesError && outcomesError.msg}
          >
            <div className="settings-holder">
              <Checkbox
                checked={accountSettings.showFederalStandards}
                label={
                  <Text data-automation="ShowFederalStandardsToggle">{formatMessage('Show Common Core Standards')}</Text>
                }
                onChange={this.handleSettingsChange}
                size="small"
                value="showFederalStandards"
                variant="toggle"
                data-automation="ShowFederalStandardsCheckbox"
              />
            </div>

            <div
              className="settings-holder"
              style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
            >
              <Checkbox
                inline
                checked={accountSettings.showStateStandards}
                label={<Text data-automation="ShowStateStandardsToggle">{formatMessage('Show State Standards')}</Text>}
                onChange={this.handleSettingsChange}
                size="small"
                value="showStateStandards"
                variant="toggle"
                data-automation="ShowStateStandardsCheckbox"
              />
              <OutcomeAuthoritySelect
                id="stateStandard"
                key="stateStandard"
                ref="select"
                name="State Selector"
                aria-label={formatMessage('State Selector')}
                noneLabel={formatMessage('Select State')}
                disabled={!accountSettings.showStateStandards}
                value={(accountSettings.showStateStandards && stateStandard) || OutcomeAuthoritySelect.noneValue}
                onChange={this.handleSelectStateChange}
              />
            </div>
          </FormGroup>
        </form>
      </div>
    );
  }
}

AccountSettings.propTypes = {
  accountState: PropTypes.object,
  userState: PropTypes.object,
  session: PropTypes.object
};
