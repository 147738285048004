import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import * as searchPaths from '../../constants/search-paths';
import * as paginatedPageSizes from '../../constants/paginated-page-sizes';
import { createFetchResults } from '../../common/util/create-fetch-results';

// Components
import SearchObjects from '../search/search-objects';
import NoResult from '../common/no-result';
import Loading from '../common/loading';

export default class AccountSharedObjects extends React.Component {
  static displayName = 'AccountSharedObjects'

  static propTypes = {
    session: PropTypes.object.isRequired,
    groups: PropTypes.object
  }

  render () {
    const { groups, session, results } = this.props;
    const { isAdmin, account, publicOnly, isAccountCurator = false } = session;

    if (groups.listIsLoading) {
      return (
        <div className="AppHandler--loading">
          <Loading />
        </div>
      );
    }

    const baseParams = {};

    if (isAdmin || isAccountCurator) {
      baseParams.accountId = account.id;
      baseParams.showPublic = true;
    }

    const canShowPublic = (isAdmin || isAccountCurator) && !publicOnly;
    const noResultsMessage = results.total
      ? formatMessage('No Results Found')
      : formatMessage('No Resources To Manage');

    const resultsPerPage = paginatedPageSizes.MANAGED_RESOURCES_PAGE_SIZE;
    const fetchResults = createFetchResults(searchPaths.RESOURCES_MANAGED, resultsPerPage);

    return (
      <SearchObjects
        baseParams={baseParams}
        fetchResults={fetchResults}
        resultsPerPage={resultsPerPage}
        showDeleteOptions
        searchPrivateObjects={false}
        emptyMessage={
          <NoResult
            title={noResultsMessage}
            className="AccountResources-no-result"
          />
        }
        canShowPublic={canShowPublic}
        {...this.props}
      />
    );
  }
}
