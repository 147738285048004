import React from 'react';
import formatMessage from 'format-message';
import { View } from '@instructure/ui-view';
import { Popover } from '@instructure/ui-popover';
import { CloseButton } from '@instructure/ui-buttons';
import { IconStopSolid, IconCheckLine } from '@instructure/ui-icons';
import { TextInput } from '@instructure/ui-text-input';
import ScreenReaderContent from '@instructure/ui-a11y-content/lib/ScreenReaderContent';

import Button from '../common/button';

const BRANDING_COLORS = {
  '#E71F63': formatMessage('Magenta'),
  '#BD3C14': formatMessage('Brick'),
  '#FF2717': formatMessage('Red'),
  '#8F3E97': formatMessage('Purple'),
  '#65499D': formatMessage('Deep Purple'),
  '#4554A4': formatMessage('Indigo'),
  '#1770AB': formatMessage('Blue'),
  '#0B9BE3': formatMessage('Light Blue'),
  '#06A3B7': formatMessage('Cyan'),
  '#009688': formatMessage('Teal'),
  '#009606': formatMessage('Green'),
  '#8D9900': formatMessage('Olive'),
  '#D97900': formatMessage('Pumpkin'),
  '#FD5D10': formatMessage('Orange'),
  '#F06291': formatMessage('Pink')
};

export const DEFAULT_BRANDING_COLOR = '#8F3E97';

export default class BrandingColorPicker extends React.PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      colorPickerPopover: false,
      customColor: null
    };
  }

  renderColorButton = (color, name, custom = false) => {
    const activeColor =
      this.state.customColor ||
      (this.props.accountCuratedBannerColor || DEFAULT_BRANDING_COLOR);
    const selected = color === activeColor;

    return (
      <Button
        key={Math.random() * 9999}
        className="customBranding-colorButton"
        elementRef={button => {
          this.setColorOnInnerSpan(button, selected ? '#fff' : color);

          if (selected) {
            button.querySelector('svg').style.backgroundColor = color;
            button.classList.add('checked');
          }
        }}
        renderIcon={selected ? IconCheckLine : IconStopSolid}
        onClick={() => {
          !custom
            ? this.setColor(color)
            : document.querySelector('.colorPicker-custom input').focus();
        }}
        title={name}
      >
        <ScreenReaderContent>{name}</ScreenReaderContent>
        {selected && (
          <ScreenReaderContent>
            {formatMessage('color currently selected')}
          </ScreenReaderContent>
        )}
      </Button>
    );
  }

  saveColor = () => {
    if (this.state.customColor) {
      this.setColor(this.state.customColor);
    } else {
      this.togglePopover(); // nothing to save
    }
  }

  setCustomBrandingColor = event => {
    event.preventDefault();
    if (event.target.value.match(/^#(?:[0-9a-fA-F]{3}){1,2}$/)) {
      this.setState({ customColor: event.target.value });
    } else if (this.state.customColor) {
      this.setState({ customColor: null });
    }
  }

  setColor = color => {
    this.togglePopover();
    this.setState(
      {
        customColor: null
      },
      () => {
        this.setColorOnInnerSpan(
          document.querySelector('.customBranding-colorButton'),
          color,
        );
        this.props.onChange(color);
      }
    );
  }

  setColorOnInnerSpan = (button, color) => {
    const innerSpanToChange = button.querySelector('span');

    innerSpanToChange.style.color = color;
  }

  togglePopover = () => {
    this.setState({
      colorPickerPopover: !this.state.colorPickerPopover
    });
  }

  render = () => {
    let { accountCuratedBannerColor = null } = this.props;
    if (!accountCuratedBannerColor) { accountCuratedBannerColor = DEFAULT_BRANDING_COLOR; }

    const customColor = this.state.customColor
      ? this.state.customColor
      : Object.keys(BRANDING_COLORS).indexOf(accountCuratedBannerColor) === -1
        ? accountCuratedBannerColor
        : '';

    return (
      <div className="customBranding-color">
        <Popover
          on="click"
          isShowingContent={this.state.colorPickerPopover}
          onHideContent={this.togglePopover}
          shouldContainFocus
          shouldReturnFocus
          shouldCloseOnDocumentClick
          screenReaderLabel={formatMessage('Select background color')}
          offsetY="16px"
          renderTrigger={<Button
            className="customBranding-colorButton"
            elementRef={button => {
              this.setColorOnInnerSpan(button, accountCuratedBannerColor);
            }}
            key="customBranding-popoverTrigger"
            renderIcon={IconStopSolid}
            onClick={this.togglePopover}
          >
            <ScreenReaderContent>
              {formatMessage('Change the curated content banner color')}
            </ScreenReaderContent>
          </Button>}
        >

          <View
            className="colorList"
            padding="medium"
            display="flex"
            as="form"
          >
            <CloseButton
              placement="end"
              offset="x-small"
              color="primary"
              onClick={this.togglePopover}
              screenReaderLabel={formatMessage('Close')}
            />
            {Object.keys(BRANDING_COLORS).map(color =>
              this.renderColorButton(
                color,
                  `${BRANDING_COLORS[color]} (${color})`
              )
            )}
            <div className="colorPicker-custom">
              {this.renderColorButton(
                customColor,
                formatMessage('Custom color'),
                'custom'
              )}
              <TextInput
                size="small"
                display="inline-block"
                renderLabel={
                  <ScreenReaderContent>
                    {formatMessage('Custom HEX color string')}
                  </ScreenReaderContent>
                }
                onChange={this.setCustomBrandingColor}
                onKeyDown={e => {
                  if (e.which === 13) {
                    e.preventDefault();
                    return this.saveColor();
                  }
                }}
                placeholder="#FFFFFF"
              />
            </div>

            <div className="colorPicker-custom">
              <Button onClick={this.togglePopover}>
                {formatMessage('Cancel')}
              </Button>
              <Button
                onClick={this.saveColor}
                color={Button.color.primary}
                margin="0 0 0 x-small"
              >
                {formatMessage('Apply')}
              </Button>
            </div>
          </View>
        </Popover>
        {formatMessage('Select background color')}
      </div>
    );
  }
}
