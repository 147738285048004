import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import formatMessage from 'format-message';
import classnames from 'classnames';
import { IconStarLightLine, IconCollectionSaveLine, IconOutcomesLine } from '@instructure/ui-icons';
import { Flex } from '@instructure/ui-flex';
import ScreenReaderContent from '@instructure/ui-a11y-content/lib/ScreenReaderContent';
import { TruncateText } from '@instructure/ui-truncate-text/lib';

import Link from '../common/link';
import * as announce from '../../common/announce';
import AnalyticsActions from '../../actions/analytics';
import gradeText from './gradeText';
import ResultThumbnail from '../search/result/thumbnail';
import { resourceTypes } from '../../common/app-constants';
import { shortenLargeNumber } from '../../common/util/string';

const delay = 0.2;

export default createReactClass({
  displayName: 'PreviewCard',

  getInitialState () {
    return {
      focus: false,
      isHovered: false,
      isFocused: false
    };
  },

  propTypes: {
    available: PropTypes.bool,
    displayApprovedBanner: PropTypes.bool,
    isLink: PropTypes.bool,
    resource: PropTypes.object,
    showPreview: PropTypes.bool,
    curatedBanner: PropTypes.object
  },

  getDefaultProps () {
    return {
      available: true,
      showPreview: true,
      isLink: true
    };
  },

  componentWillUnmount () {
    clearTimeout(this.hoverFocusDelayTimer);
  },

  handleClick () {
    AnalyticsActions.logResultSelection();
    announce.reset();
  },

  handleLinkFocus () {
    this.setState({ focus: true });
  },

  handleLinkBlur () {
    this.setState({ focus: false });
  },

  handleMouseEnter () {
    clearTimeout(this.hoverFocusDelayTimer);
    this.hoverFocusDelayTimer = setTimeout(() => {
      this.setState({ isHovered: true });
    }, delay * 1000);
  },

  handleMouseLeave () {
    clearTimeout(this.hoverFocusDelayTimer);
    this.setState({ isHovered: false });
  },

  handleFocus () {
    clearTimeout(this.hoverFocusDelayTimer);
    this.hoverFocusDelayTimer = setTimeout(() => {
      this.setState({ isFocused: true });
    }, delay * 1000);
  },

  handleBlur () {
    clearTimeout(this.hoverFocusDelayTimer);
    this.setState({ isFocused: false });
  },

  render () {
    const { resource } = this.props;
    const authors = resource.authors || [];
    const outcomes = resource.outcomes;
    const previewCardClasses = classnames('PreviewCard', {
      'PreviewCard--unavailable': this.props.available === false,
      'PreviewCard--focus': this.state.focus
    });
    const isFeatured = resource.featured;

    const previewCardTestIds = classnames('PreviewCard', {
      Unavailable: this.props.available === false,
      Featured: isFeatured
    });

    const unavailableDiv = this.props.available === false && (
      <div className="PreviewCard--unavailable-label" data-automation="PreviewCardUnavailableLabel">
        {formatMessage('Unavailable')}
      </div>
    );

    const displayApprovedBanner = this.props.displayApprovedBanner;
    const curatedBanner = this.props.curatedBanner;
    const displayOutcomes = Array.isArray(outcomes) && outcomes.length > 0;
    const shortDownloadCounter = shortenLargeNumber(resource.downloadCount, 1);
    const shortFavoriteCounter = shortenLargeNumber(resource.favoritedCount, 1);

    const title = this.props.isLink ? (
      <Link
        className="PreviewCard-title-link"
        data-automation="PreviewCardTitleLink"
        href={`/resources/${resource.id}`}
        onBlur={this.handleLinkBlur}
        onClick={this.handleClick}
        onFocus={this.handleLinkFocus}
      >
        <span
          className="PreviewCard-title-link-text"
          id={`lor-label-${resource.id}`}
        >
          {resource.title}
        </span>
      </Link>
    ) : (
      resource.title
    );
    const featured = isFeatured ? [
      <div
        key="1"
        className={`background-color type-${resource.type || 'course'} featuredRibbon`}>
        {formatMessage('featured')}
      </div>,
      <div key="2" className={`color-${resource.type || 'course'} featuredRibbonTriangle`} />]
      : [];

    return (
      <div
        className={previewCardClasses}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        data-automation={previewCardTestIds}
      >
        <ResultThumbnail
          displayApprovedBanner={displayApprovedBanner}
          learningObject={resource}
          isHovered={this.state.isHovered}
          isFocused={this.state.isFocused}
          showPreview={this.props.showPreview}
          curatedBanner={curatedBanner}
        />
        <div className="PreviewCard-wrapper">
          <div className="PreviewCard-title-description">
            <h3
              className={`PreviewCard-resourceType font-color type-${resource.type}`}
              data-automation="PreviewCardResourceType"
            >
              {resourceTypes[resource.type].singularName}
            </h3>
            <h2 className="PreviewCard-title" data-automation="PreviewCardResourceTitle">{title}</h2>

            <div
              className="PreviewCard-description"
              id={`lor-description-${resource.id}`}
            >
              {displayApprovedBanner && (
                <span className="screenreader-only">
                  {formatMessage('{institution} approved content', {
                    institution: resource.account ? resource.account.name : ''
                  })}
                </span>
              )}
            </div>

            <div
              className="PreviewCard-grades"
              data-automation="PreviewCardGrades"
              title={formatMessage('Grades')}
            >
              <span className="screenreader-only">
                {formatMessage('Grades:')}
              </span>
              <TruncateText>{gradeText(resource.gradeIds)}</TruncateText>
            </div>

            <div className="PreviewCard-authors" data-heap-redact-text data-automation="PreviewCardAuthor">
              <span className="screenreader-only">
                {formatMessage('Shared by:')}
              </span>
              {authors[0] && authors[0].name}
            </div>
          </div>
          <div className="PreviewCard-bottom-icons">
            <Flex>
              <Flex.Item padding="0 x-small x-small 0" data-automation="PreviewCardDownloadIcon">
                <IconCollectionSaveLine />
              </Flex.Item>
              <Flex.Item padding="0 0 0" data-automation="PreviewCardDownloadCounter">
                <span
                  className="PreviewCard-icon-description lor-instui-cursor-default lor-instui-simple-button"
                  aria-hidden="true"
                >
                  {shortDownloadCounter}
                </span>
                <ScreenReaderContent>
                  {formatMessage('{count} Downloads', {
                    count: shortDownloadCounter
                  })}
                </ScreenReaderContent>
              </Flex.Item>
              <Flex.Item padding="0 x-small x-small" margin="0 0 0 medium" data-automation="PreviewCardFavoriteIcon">
                <IconStarLightLine />
              </Flex.Item>
              <Flex.Item padding="0 0 0" data-automation="PreviewCardFavoriteCounter">
                <span
                  className="PreviewCard-icon-description lor-instui-cursor-default lor-instui-simple-button"
                  aria-hidden="true"
                >
                  {shortFavoriteCounter}
                </span>
                <ScreenReaderContent>
                  {formatMessage('{count} Favorites', {
                    count: shortFavoriteCounter
                  })}
                </ScreenReaderContent>
              </Flex.Item>
              <Flex.Item padding="0 x-small x-small">
                {displayOutcomes && <IconOutcomesLine />}
              </Flex.Item>
              <Flex.Item padding="0 0 0">
                {displayOutcomes && (
                  <span className="PreviewCard-icon-description lor-instui-cursor-default lor-instui-simple-button">
                    <span aria-hidden="true">{outcomes.length}</span>
                    <ScreenReaderContent>
                      {formatMessage(
                        'This resource is aligned with standard outcomes'
                      )}
                    </ScreenReaderContent>
                  </span>
                )}
              </Flex.Item>
            </Flex>
          </div>
        </div>
        {featured}
        {unavailableDiv}
      </div>
    );
  }
});
