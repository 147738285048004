import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import classNames from 'classnames';
import { Spinner } from '@instructure/ui-spinner';

export const LoadingSpinner = ({ size = 'medium', message = formatMessage('Loading...'), className, ...rest }) => (
  <div className={classNames('loading-spinner', className)} {...rest}>
    <Spinner renderTitle={message} size={size} />
    <span className="screenreader-only">{message}</span>
  </div>
);

LoadingSpinner.propTypes = {
  size: PropTypes.string,
  message: PropTypes.node,
  className: PropTypes.string
};
