import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import * as searchPaths from '../../constants/search-paths';
import * as paginatedPageSizes from '../../constants/paginated-page-sizes';
import { createFetchResults } from '../../common/util/create-fetch-results';

// Components
import SearchObjects from '../search/search-objects';
import NoResult from '../common/no-result';

// Stores
import ResultsStore from '../../stores/results';

export default class ImportedHandler extends React.Component {
  static isResultView = true

  static propTypes = {
    session: PropTypes.object
  }

  render () {
    const { session, results } = this.props;

    const hasImported = Boolean(results.total);

    const noResultsMessage = results.total
      ? formatMessage('No Results Found')
      : formatMessage('No Imported Resources');

    const resultsPerPage = paginatedPageSizes.IMPORTED_PAGE_SIZE;
    const fetchResults = createFetchResults(searchPaths.RESOURCES, resultsPerPage);

    return (
      <SearchObjects
        baseParams={{ onlyImported: true }}
        fetchResults={fetchResults}
        resultsPerPage={resultsPerPage}
        queryArialLabel={formatMessage(
          'Search My Imported Resources for course resources by keyword, author, institution, or title'
        )}
        isLoading={ResultsStore.showLoading()}
        searchPrivateObjects
        emptyMessage={
          <NoResult
            title={noResultsMessage}
            message={
              !hasImported &&
              formatMessage(
                'Import resources from Commons by clicking the "Import/Download" button in the Resource details page.'
              )
            }
          />
        }
        canShowPublic={!session.publicOnly}
        {...this.props}
      />
    );
  }
}
