import React, { useMemo } from 'react';
import formatMessage from 'format-message';
import TypePicker from './type-picker';
import { getState } from '../../store';

const DEFAULT_TYPE = Symbol('default');

const TYPES = [
  { typeName: 'course', featureFlag: DEFAULT_TYPE },
  { typeName: 'module', featureFlag: DEFAULT_TYPE },
  { typeName: 'assignment', featureFlag: DEFAULT_TYPE },
  { typeName: 'quiz', featureFlag: DEFAULT_TYPE },
  { typeName: 'quizzesnext', featureFlag: 'quizzesnext' },
  { typeName: 'discussion_topic', featureFlag: DEFAULT_TYPE },
  { typeName: 'page', featureFlag: DEFAULT_TYPE },
  { typeName: 'document', featureFlag: DEFAULT_TYPE },
  { typeName: 'video', featureFlag: DEFAULT_TYPE },
  { typeName: 'audio', featureFlag: DEFAULT_TYPE },
  { typeName: 'image', featureFlag: DEFAULT_TYPE },
];

const computeTypes = (featureFlags) => {
  return TYPES
    .filter(({ featureFlag }) => featureFlag === DEFAULT_TYPE || featureFlags[featureFlag])
    .map(({ typeName }) => typeName);
};

export default function ResourceTypePicker (props) {
  const state = getState();
  const featureFlags = state.featureFlags.flags;
  const types = useMemo(() => computeTypes(featureFlags), [featureFlags]);

  return (
    <fieldset className="ResourceTypePicker">
      <legend className="screenreader-only">
        {formatMessage('View only these types')}
      </legend>
      <div className="ResourceTypePicker-types">
        {types.map((type) => (
          <TypePicker key={type} type={type} {...props} />
        ))}
      </div>
    </fieldset>
  );
}
