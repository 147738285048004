import {
  CHANGE_ACCOUNT_SETTINGS,
  CLEAR_SAVE_STATE,
  LOAD_CURATOR_START,
  LOAD_CURATOR_SUCCESS,
  LOAD_CURATOR_FAILURE,
  SAVE_ACCOUNT_SETTINGS_PENDING,
  SAVE_ACCOUNT_SETTINGS_ERROR,
  SAVE_ACCOUNT_SETTINGS_SUCCESS,
  SET_ACCOUNT_CURATORS_SUCCESS,
  SAVE_ACCOUNT_CURATED_BANNER_IMAGE
} from '../actions/account';
import { LOAD_SESSION_SUCCESS } from '../actions/session';
import Error from './error-record';
import OutcomeAuthoritySelect from '../components/common/outcome-authority-select';

export const initialState = {
  settings: {
    allowApprovedContent: false,
    curators: [],
    publicOnly: false,
    canSharePublic: true,
    canShowPublic: true,
    canShowFeatured: true,
    showCuratedResourcesFirst: false,
    showFederalStandards: true,
    showStateStandards: true,
    stateStandard: OutcomeAuthoritySelect.noneValue,
    showNextGenStandards: true,
    accountCuratedBannerImage: null,
    accountCuratedBannerColor: null,
    defaultSearchFilters: {
      enabled: false
    },
    denyShareWithInstance: false,
  },
  loadAccountCuratorPending: false,
  loadAccountCuratorSuccessful: false,
  loadAccountCuratorFailure: null,
  saveAccountSettingsPending: false,
  saveAccountSettingsErrors: null,
  saveAccountSettingsSuccessful: false,
  oldSettings: ''
};

export default function accountReducer (state = initialState, action) {
  switch (action.type) {
    case LOAD_SESSION_SUCCESS:
    {
      const {
        allowApprovedContent,
        publicOnly,
        canSharePublic,
        canShowPublic,
        canShowFeatured,
        showCuratedResourcesFirst,
        showFederalStandards,
        showStateStandards,
        stateStandard,
        showNextGenStandards,
        defaultSearchFilters,
        accountCuratedBannerImage,
        accountCuratedBannerColor,
        denyShareWithInstance
      } = action.session;

      const settings = {
        allowApprovedContent,
        publicOnly,
        canSharePublic,
        canShowPublic,
        canShowFeatured,
        showCuratedResourcesFirst,
        showFederalStandards,
        showStateStandards,
        stateStandard,
        showNextGenStandards,
        defaultSearchFilters,
        accountCuratedBannerImage,
        accountCuratedBannerColor,
        denyShareWithInstance
      };

      return {
        ...state,
        settings,
        oldSettings: settings
      };
    }
    case CHANGE_ACCOUNT_SETTINGS:
      return {
        ...state,
        settings: action.values
      };
    case CLEAR_SAVE_STATE:
      return {
        ...state,
        saveAccountSettingsPending: false,
        saveAccountSettingsErrors: null,
        saveAccountSettingsSuccessful: false
      };
    case LOAD_CURATOR_START:
      return {
        ...state,
        loadAccountCuratorPending: true,
        loadAccountCuratorSuccessful: false,
        loadAccountCuratorFailure: null
      };
    case LOAD_CURATOR_SUCCESS:
      return {
        ...state,
        settings: { ...state.settings, curators: action.curators },
        loadAccountCuratorPending: false,
        loadAccountCuratorSuccessful: true,
        loadAccountCuratorFailure: null
      };
    case LOAD_CURATOR_FAILURE:
      return {
        ...state,
        loadAccountCuratorPending: false,
        loadAccountCuratorSuccessful: false,
        loadAccountCuratorFailure: new Error(action.error)
      };
    case SAVE_ACCOUNT_SETTINGS_PENDING:
      return {
        ...state,
        saveAccountSettingsPending: true,
        saveAccountSettingsErrors: null,
        saveAccountSettingsSuccessful: false
      };
    case SAVE_ACCOUNT_SETTINGS_ERROR:
      return {
        ...state,
        saveAccountSettingsPending: false,
        saveAccountSettingsErrors: new Error(action.error),
        saveAccountSettingsSuccessful: false
      };
    case SAVE_ACCOUNT_SETTINGS_SUCCESS:
      return {
        ...state,
        oldSettings: state.settings,
        settings: action.settings,
        saveAccountSettingsPending: false,
        saveAccountSettingsErrors: null,
        saveAccountSettingsSuccessful: true
      };
    case SET_ACCOUNT_CURATORS_SUCCESS:
      return {
        ...state,
        settings: { ...state.settings, curators: action.curators || [] },
        saveAccountSettingsPending: false,
        saveAccountSettingsErrors: null,
        saveAccountSettingsSuccessful: true
      };
    case SAVE_ACCOUNT_CURATED_BANNER_IMAGE:
      return {
        ...state,
        settings: {
          ...state.settings,
          accountCuratedBannerImage: action.image || null
        },
        saveAccountSettingsPending: false,
        saveAccountSettingsErrors: null,
        saveAccountSettingsSuccessful: true
      };
    default:
      return state;
  }
}
