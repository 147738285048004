import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import PreviewCard from '../../resources/preview-card';
import ConfirmAction from '../../common/confirm-action';
import { IconStarSolid } from '@instructure/ui-icons';

import { displayAsApprovedContent } from '../../../common/util/approved-content';
import ResourceProgressStateHandler from '../../resources/resource-progress-state/resource-progress-state-handler';
import ResourcePogress from '../../resources/resource-progress-state/resource-progress';

export default class SearchResult extends React.Component {
  constructor (props) {
    super(props);
    this.onDelete = this.onDelete.bind(this);
  }

  static propTypes = {
    learningObject: PropTypes.object.isRequired,
    onDelete: PropTypes.func,
    onRemoveFavorite: PropTypes.func,
    showDeleteOptions: PropTypes.bool,
    showFavoriteOptions: PropTypes.bool,
    isNewShareWorkflow: PropTypes.bool,
    search: PropTypes.object,
    curatedBanner: PropTypes.object,
  }

  static defaultProps = {
    learningObject: {
      authors: []
    },
    onDelete: () => {},
    onRemoveFavorite: () => {},
    showDeleteOptions: false,
    showFavoriteOptions: false,
  }

  shouldComponentUpdate (props, state) {
    return (
      this.props.learningObject !== props.learningObject ||
      this.props.showDeleteOptions !== props.showDeleteOptions ||
      this.props.search !== props.search ||
      this.props.onDelete !== props.onDelete ||
      this.props.onRemoveFavorite !== props.onRemoveFavorite
    );
  }

  onDelete () {
    this.props.onDelete(this.props.learningObject.id);
  }

  onRemoveFavorite = async () => {
    await this.props.onRemoveFavorite(this.props.learningObject);
  }

  render () {
    const { learningObject, isNewShareWorkflow } = this.props;
    const displayApprovedBanner = displayAsApprovedContent({
      allowApprovedContent: this.props.allowApprovedContent,
      scopeIds: learningObject.scopeIds,
      accountId: this.props.accountId,
      learningObjectAccountId: learningObject.account
        ? learningObject.account.id
        : ''
    });

    const available =
      learningObject.available === undefined ? true : learningObject.available;

    const result = (
      <div
        className="lor-result-list-item"
      >
        <div className="lor-result">
          <ResourceProgressStateHandler
            isNewShareWorkflow={isNewShareWorkflow}
            resource={learningObject}>
            {(inProgress, progress, onFinish, retryFailedJob) =>
              inProgress ? (
                <ResourcePogress
                  resource={learningObject}
                  available={available}
                  displayApprovedBanner={displayApprovedBanner}
                  curatedBanner={this.props.curatedBanner}
                  progress={progress}
                  onFinish={onFinish}
                  retryFailedJob={retryFailedJob}
                />
              ) : (
                <PreviewCard
                  resource={learningObject}
                  available={available}
                  displayApprovedBanner={displayApprovedBanner}
                  curatedBanner={this.props.curatedBanner}
                />
              )}
          </ResourceProgressStateHandler>
        </div>
      </div>
    );
    if (this.props.showDeleteOptions && available) {
      return (
        <ConfirmAction
          key={`delete-${learningObject.id}`}
          onPerformAction={this.onDelete}
          confirmMessage={formatMessage('Delete {title}', {
            title: learningObject.title
          })}
          onConfirmMessage={formatMessage('{title} Deleted', {
            title: learningObject.title
          })}
          confirmationMessage={formatMessage(
            'You will permanently delete {name}.',
            { name: learningObject.title }
          )}
          confirmationTitle={
            formatMessage('Delete {name}?', {
              name: learningObject.title
            })
          }
          confirmationTextButton={formatMessage('Delete')}
        >
          {result}
        </ConfirmAction>
      );
    }
    if (this.props.showFavoriteOptions) {
      return (
        <ConfirmAction
          key={`delete-${learningObject.id}`}
          onPerformAction={this.onRemoveFavorite}
          confirmMessage={formatMessage('Remove {title} from favorites', {
            title: learningObject.title
          })}
          onConfirmMessage={formatMessage('{title} Removed from favorites', {
            title: learningObject.title
          })}
          confirmationMessage={formatMessage(
            'You will remove {name} from favorites.',
            { name: learningObject.title }
          )}
          confirmationTitle={
            formatMessage('Remove {name} from favorites?', {
              name: learningObject.title
            })
          }
          confirmationTextButton={formatMessage('Remove')}
          actionIcon={<IconStarSolid color="brand" />}
        >
          {result}
        </ConfirmAction>
      );
    }

    return (
      <li style={{ listStyle: 'none' }} key={learningObject.id}>
        {result}
      </li>
    );
  }
}
