import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import formatMessage, { date } from 'format-message';
import { Table } from '@instructure/ui-table';
import { Flex } from '@instructure/ui-flex';
import { Text } from '@instructure/ui-text';
import { resourceTypes } from '../../../common/app-constants';
import Link from '../../common/link';
import Pill from '../../common/pill';
import ResourceTypeIcon from '../../icons/resource-type';
import { mapToGradeAbbreviation, sortGrades } from '../../resources/gradeText';
import TruncateWithTooltip from '../../common/truncate-with-tooltip';

const convertToSortedGradeLabels = (gradeIds) => {
  return gradeIds.sort(sortGrades).map(mapToGradeAbbreviation).join(', ');
};

const ResourceType = ({ resourceType, isDesktop }) => {
  return (
    <React.Fragment>
      <ResourceTypeIcon
        className="resource-type-icon--outline"
        type={resourceType}
        colored
        ghost
        raw />
      {!isDesktop && (<div className={`font-color type-${resourceType}`}>
        <Text size="x-small" weight="bold" transform="uppercase">
          {resourceTypes[resourceType].singularName}
        </Text>
      </div>)}
    </React.Fragment>
  );
};

// group the featured pill and curated banner icon together for stacked view
const FeaturedPillWithCuratedBanner = ({ isFeatured, resourceType, curatedBanner }) => {
  return (
    <Flex
      gap="small"
      alignItems="center"
    >
      {isFeatured && (
        <Pill
          className={`type-${resourceType} background-color`}
          themeOverride={{ primaryColor: 'white', padding: '2px 8px' }}
        >
          {formatMessage('Featured')}
        </Pill>
      )}
      {curatedBanner && (
        <div
          style={{
            backgroundImage: `url(${curatedBanner.image})`,
            backgroundColor: curatedBanner.color || 'var(--color-magenta)',
          }}
          className="curated-banner-icon"
        />
      )}
    </Flex>
  );
};

const ResourceTitleCell = ({ resource, isDesktop, curatedBanner }) => {
  return (
    <div
      className={classnames('resource-title-cell', { 'resource-title-cell--stacked': !isDesktop })}
    >
      <Flex gap="small">
        <ResourceType
          resourceType={resource.type}
          isDesktop={isDesktop}
        />
      </Flex>
      <Link
        href={`/resources/${resource.id}`}
        className="resource-title-link"
      >
        <TruncateWithTooltip text={resource.title} />
      </Link>
      {(resource.featured || curatedBanner) && (
        <FeaturedPillWithCuratedBanner
          isFeatured={resource.featured}
          resourceType={resource.type}
          curatedBanner={curatedBanner}
        />)}
    </div>
  );
};

const GradeLevelsCell = ({ resource }) => {
  return !!resource.gradeIds && <TruncateWithTooltip text={convertToSortedGradeLabels(resource.gradeIds)} />;
};

const AuthorCell = ({ resource }) => {
  const author = resource.authors && resource.authors[0];

  if (!author) {
    return null;
  }

  return (
    <React.Fragment>
      {/* TODO: display avatar when we have the unified avatar url */}
      {/* <Avatar
        data-heap-redact-attributes="url"
        url={author.avatarUrl}
      /> */}
      <TruncateWithTooltip
        text={author.name}
        className="author-name"
      />
    </React.Fragment>
  );
};

const ListRow = (props) => {
  const { resource, isDesktop, curatedBanner, ...rest } = props;

  return (
    <Table.Row
      {...rest}
      data-display={resource.featured ? 'featured' : 'default'}
      data-type={resource.type}
    >
      <Table.Cell>
        <ResourceTitleCell
          resource={resource}
          isDesktop={isDesktop}
          curatedBanner={curatedBanner}
        />
      </Table.Cell>
      <Table.Cell>
        <GradeLevelsCell
          resource={resource}
        />
      </Table.Cell>
      <Table.Cell>
        <AuthorCell
          resource={resource}
        />
      </Table.Cell>
      <Table.Cell
        textAlign={isDesktop ? 'center' : 'start'}
      >
        <span>{date(Date.parse(resource.updateDate))}</span>
      </Table.Cell>
      <Table.Cell
        textAlign={isDesktop ? 'center' : 'start'}
      >
        <span>{resource.favoritedCount}</span>
      </Table.Cell>
      <Table.Cell
        textAlign={isDesktop ? 'center' : 'start'}
      >
        <span>{resource.downloadCount}</span>
      </Table.Cell>
    </Table.Row>
  );
};

ListRow.propTypes = {
  resource: PropTypes.object.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  curatedBanner: PropTypes.object,
};

ListRow.displayName = 'Row';

export default ListRow;
