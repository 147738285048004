import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import formatMessage from 'format-message';
import range from 'lodash/utility/range';
import classnames from 'classnames';

import Link from '../common/link';
import { FavoritesActions } from '../../actions';
import ResultsStore from '../../stores/results';
import LoadMore from '../common/load-more';
import ResultComponent from './result/result';
import ResultPlaceholder from './result/placeholder';
import LearningObjectActions from '../../actions/resource';
import Message from '../common/message';
import ComplexMessage from '../common/complex-message';
import { getState } from '../../store';

export default createReactClass({
  displayName: 'SearchResults',

  propTypes: {
    fetchResults: PropTypes.func.isRequired,
    results: PropTypes.object.isRequired,
    showDeleteOptions: PropTypes.bool,
    showFavoriteOptions: PropTypes.bool,
    search: PropTypes.object
  },

  onDelete (learningObjectId) {
    const deleted = this.refs[learningObjectId];
    const item = deleted && ReactDOM.findDOMNode(deleted);
    const previousResult = item && item.previousSibling;
    if (previousResult) {
      previousResult.querySelector('a').focus();
    } else {
      ReactDOM.findDOMNode(this).focus();
    }
    LearningObjectActions.destroyById(learningObjectId);
  },

  async onRemoveFavorite (learningObject) {
    const deleted = this.refs[learningObject.id];
    const item = deleted && ReactDOM.findDOMNode(deleted);
    const previousResult = item && item.previousSibling;

    const result = await FavoritesActions.removeFromFavorites(learningObject);
    if (result) {
      if (previousResult) {
        previousResult.querySelector('a').focus();
      } else {
        ReactDOM.findDOMNode(this).focus();
      }
      ResultsStore.handleRemoveFromFavorites(learningObject.id);
    }
    return !!result;
  },

  render () {
    const isLoading = this.props.results.store.showLoading();
    const results = this.props.results.store.get();
    const listClassnames = classnames('SearchResults-list', {
      'SearchResults-list-loading': this.props.results.loading
    });

    const account = this.props.account;
    const curatedBanner = {
      color: account
        ? account.settings.accountCuratedBannerColor || null
        : null,
      image: account ? account.settings.accountCuratedBannerImage || null : null
    };

    const isNewShareWorkflow = getState().featureFlags.flags.new_share_workflow;

    return (
      <div className="SearchResults">
        <div className={listClassnames}>
          <LoadMore
            hasMore={this.props.results.hasMore}
            isLoading={isLoading}
            loadMore={this.props.fetchResults}
          >
            {results.length
              ? results
                .map(learningObject => {
                  return <ResultComponent
                    ref={learningObject.id}
                    key={learningObject.id}
                    accountId={this.props.session.accountId}
                    curatedBanner={curatedBanner}
                    allowApprovedContent={
                      this.props.session.allowApprovedContent
                    }
                    isNewShareWorkflow={isNewShareWorkflow}
                    learningObject={learningObject}
                    showDeleteOptions={this.props.showDeleteOptions}
                    showFavoriteOptions={this.props.showFavoriteOptions}
                    onDelete={this.onDelete}
                    onRemoveFavorite={this.onRemoveFavorite}
                    search={this.props.search}
                  />;
                })
              : undefined}
          </LoadMore>
          {this.props.results.error && (
            <Message type="error2 message-with-icon">
              <ComplexMessage>
                {formatMessage(
                  'There was an error loading the results.\nYou can try again by {clicking_here}.',
                  { clicking_here: ComplexMessage.placeholder('retry') }
                )}
                <Link key="retry" onClick={this.props.fetchResults}>
                  {formatMessage('clicking here')}
                </Link>
              </ComplexMessage>
            </Message>
          )}
        </div>
        <div className="SearchResults-list-placeholder">
          <div className="LoadMore">
            {isLoading &&
              range(16)
                .map(key => <ResultPlaceholder key={key} />)
                .concat(
                  range(8).map(index => {
                    return (
                      <div className="lor-result-list-item" key={index + 16} />
                    );
                  })
                )}
          </div>
        </div>
      </div>
    );
  }
});
