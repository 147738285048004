import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { View } from '@instructure/ui-view';
import { Tooltip } from '@instructure/ui-tooltip';
import ScreenReaderContent from '@instructure/ui-a11y-content/lib/ScreenReaderContent';
import { debounce } from 'lodash';

function ToolTipWrapper (props) {
  const text = <TextWrapper elementRef={props.elementRef} text={props.text} />;

  if (!props.isTruncated) {
    return text;
  }

  return (
    <Tooltip
      placement="bottom"
      color="primary-inverse"
      renderTip={
        <div className="truncate-tooltip">
          {props.text}
        </div>
      }
    >
      <div tabIndex="1">
        {text}
      </div>
    </Tooltip>
  );
}

function TextWrapper (props) {
  return (
    <React.Fragment>
      <ScreenReaderContent>{props.text}</ScreenReaderContent>
      <div ref={props.elementRef} className="truncate-text" data-automation="truncatedText" aria-hidden="true" data-testid="truncated-text">
        {props.text}
      </div>
    </React.Fragment>
  );
}

const RESIZE_DEBOUNCE = 200;

export default function TruncateWithTooltip (props) {
  const [isTruncated, setIsTruncated] = React.useState(false);
  const textRef = useRef(null);

  const checkTruncation = useCallback(
    debounce(
      () => {
        const element = textRef.current;
        if (element) {
          setIsTruncated(element.scrollWidth > element.clientWidth);
        }
      },
      RESIZE_DEBOUNCE
    ),
    []
  );

  useEffect(() => {
    window.addEventListener('resize', checkTruncation);
    checkTruncation();

    return () => {
      window.removeEventListener('resize', checkTruncation);
    };
  }, [props.text]);

  return (
    <View
      as="div"
      className={props.className}
    >
      <ToolTipWrapper elementRef={textRef} isTruncated={isTruncated} text={props.text} />
    </View>
  );
}

TruncateWithTooltip.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string
};
