import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import Message from '../../common/message';
import ComplexMessage from '../../common/complex-message';
import Link from '../../common/link';

const ResultsError = ({ onRetryClick }) => {
  return (
    <Message type="error2 message-with-icon">
      <ComplexMessage>
        {formatMessage(
          'There was an error loading the results.\nYou can try again by {clicking_here}.',
          { clicking_here: ComplexMessage.placeholder('retry') }
        )}
        <Link key="retry" onClick={onRetryClick}>
          {formatMessage('clicking here')}
        </Link>
      </ComplexMessage>
    </Message>
  );
};

ResultsError.propTypes = {
  onRetryClick: PropTypes.func.isRequired
};

export default ResultsError;
