import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { Table } from '@instructure/ui-table';
import { Text } from '@instructure/ui-text';
import { Responsive } from '@instructure/ui-responsive';
import { SearchActions } from '../../../actions';
import iconApprovedSrc from '../../../../assets/svg/approved-check.svg';
import { displayAsApprovedContent } from '../../../common/util/approved-content';
import ListRow from './list-row';
import { SORTING_ORDER_ASCENDING, SORTING_ORDER_DESCENDING, SORT_AUTHOR, SORT_DOWNLOADS, SORT_FAVORITE, SORT_RECENT, SORT_TITLE } from '../../../constants/search';

const headers = [
  {
    id: SORT_TITLE,
    label: formatMessage('Title'),
    width: '40%',
    sortable: true,
  },
  {
    id: 'grade',
    label: formatMessage('Grade level'),
    sortable: false,
  },
  {
    id: SORT_AUTHOR,
    label: formatMessage('Author'),
    sortable: true,
  },
  {
    id: SORT_RECENT,
    label: formatMessage('Last updated'),
    sortable: true,
    getTextAlign: (isDesktopView) => isDesktopView ? 'center' : 'start',
  },
  {
    id: SORT_FAVORITE,
    label: formatMessage('Favorites'),
    sortable: true,
    getTextAlign: (isDesktopView) => isDesktopView ? 'center' : 'start',
  },
  {
    id: SORT_DOWNLOADS,
    label: formatMessage('Downloads'),
    sortable: true,
    getTextAlign: (isDesktopView) => isDesktopView ? 'center' : 'start',
  },
];

const ResultsList = ({
  results,
  search,
  curatedBanner,
  allowApprovedContent,
  accountId,
  sortBy,
  sortOrder
}) => {
  const isDesktopView = (matches) => matches.includes('large');

  const onChangeSort = (event, { id }) => {
    const nextOrder = sortOrder === SORTING_ORDER_ASCENDING ? SORTING_ORDER_DESCENDING : SORTING_ORDER_ASCENDING;
    SearchActions.update({ sortBy: id, sortOrder: nextOrder });
  };

  const getInstUISortDirection = (sortByKey) => {
    const sortDirection = sortOrder === SORTING_ORDER_ASCENDING ? 'ascending' : 'descending';
    return sortBy === sortByKey ? sortDirection : 'none';
  };

  const renderColHeader = (header, isDesktopView) => {
    return (
      <Table.ColHeader
        key={header.id}
        id={header.id}
        width={header.width}
        textAlign={header.getTextAlign ? header.getTextAlign(isDesktopView) : 'start'}
        {...(header.sortable && { onRequestSort: onChangeSort, sortDirection: getInstUISortDirection(header.id) })}
      >
        <Text weight="bold">{header.label}</Text>
      </Table.ColHeader>
    );
  };

  const renderHead = (responsiveProps, matches) => (
    <Table.Head renderSortLabel={formatMessage('Sort by')}>
      <Table.Row {...responsiveProps}>
        {headers.map((header) => renderColHeader(header, isDesktopView(matches)))}
      </Table.Row>
    </Table.Head>
  );

  const renderBody = (responsiveProps, matches) => (
    <Table.Body>
      {results.map((result) => (
        <ListRow
          key={result.id}
          resource={result}
          curatedBanner={canShowApprovedContent(result)
            ? { color: curatedBanner.color, image: curatedBanner.image || iconApprovedSrc }
            : null}
          isDesktop={isDesktopView(matches)}
          {...responsiveProps}
        />
      ))}
    </Table.Body>
  );

  const canShowApprovedContent = (resource) => {
    return displayAsApprovedContent({
      allowApprovedContent: allowApprovedContent,
      scopeIds: resource.scopeIds,
      accountId: accountId,
      learningObjectAccountId: resource.account
        ? resource.account.id
        : ''
    });
  };

  return (
    <div className="results-list">
      <Responsive
        match="media"
        query={{
          small: { maxWidth: 1024 },
          large: { minWidth: 1025 }
        }}
        props={{
          small: { layout: 'stacked' },
          large: { layout: 'fixed' }
        }}
      >
        {(responsiveProps, matches) => (
          <Table caption={formatMessage('Search results table')} {...responsiveProps}>
            {renderHead(responsiveProps, matches)}
            {renderBody(responsiveProps, matches)}
          </Table>
        )}
      </Responsive>
    </div>
  );
};

ResultsList.propTypes = {
  results: PropTypes.array.isRequired,
  search: PropTypes.object,
  curatedBanner: PropTypes.object,
  allowApprovedContent: PropTypes.bool,
};

export default ResultsList;
