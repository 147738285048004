import React from 'react';
import PropTypes from 'prop-types';
import { isResourcePaginated } from '../../common/util/is-resource-paginated';
import { calculatePaginationPages } from '../../common/util/calculate-pagination-pages';
import RouterStore from '../../stores/router';

// Actions
import AnalyticsActions from '../../actions/analytics';

// Components
import Results from './results';
import SearchInfo from './info';

export default class SearchObjects extends React.Component {
  static defaultProps = {
    baseParams: {}
  }

  static propTypes = {
    baseParams: PropTypes.object,
    fetchResults: PropTypes.func.isRequired,
    resultsPerPage: PropTypes.number.isRequired,
    results: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    banner: PropTypes.node,
    emptyMessage: PropTypes.node.isRequired,
    statsMessage: PropTypes.node,
    searchPrivateObjects: PropTypes.bool,
    query: PropTypes.string,
    queryArialLabel: PropTypes.string
  }

  constructor (props) {
    super(props);
    const params = this.createParamsFromProps(props);
    this.state = {
      isNewSearch: true,
      params
    };

    this.props.fetchResults(params);
  }

  createParamsFromProps = (props) => {
    return {
      ...props.baseParams,
      ...props.router.query
    };
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    if (this.state.isNewSearch && newProps.results.searchSuccessful) {
      this.setState({ isNewSearch: false });
      AnalyticsActions.viewedSearchResults(newProps.results.count);
    }

    if (this.props.router !== newProps.router) {
      const newParams = this.createParamsFromProps(newProps);

      this.props.fetchResults(newParams);
      this.setState({ isNewSearch: true, params: newParams });
    }
  }

  getPaginationProps () {
    if (!isResourcePaginated()) {
      return { isPaginated: false };
    }

    const count = this.props.results.store.getFilteredResultsCount();
    if (!count) {
      return { isPaginated: true };
    }

    return {
      ...calculatePaginationPages(RouterStore.state.query.page, count, this.props.resultsPerPage),
      isPaginated: true
    };
  }

  renderResults () {
    const paginationProps = this.getPaginationProps();
    const fetchResults = () => this.props.fetchResults(this.state.params, this.props.results.nextCursor);

    return <Results {...this.props} fetchResults={fetchResults} paginationProps={paginationProps} />;
  }

  render () {
    const empty = this.props.results.store.showEmpty();
    const statsMessage = empty ? '' : this.props.statsMessage;

    return (
      <div>
        <SearchInfo {...this.props} statsMessage={statsMessage} />
        {empty ? this.props.emptyMessage : this.renderResults()}
      </div>
    );
  }
}
