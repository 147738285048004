import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import AnalyticsActions from '../../../actions/analytics';
import RouterStore from '../../../stores/router';
import { SearchActions } from '../../../actions/';
import { getState } from '../../../store';
import { CARD_VIEW } from '../../../constants/results-view';
import SearchInfo from '../info';
import ResultCards from './cards';
import ResultsList from './list';
import { SORTING_ORDER_DESCENDING, SORT_MATCHING, SORT_RECENT } from '../../../constants/search';
import LoadMore from './load-more';
import ResourcePagination from '../../common/result-pagination';
import { calculatePaginationPages } from '../../../common/util/calculate-pagination-pages';

const createParamsFromProps = (props) => ({
  ...props.baseParams,
  ...props.router.query
});

const SearchResultsWrapper = (props) => {
  const [isNewSearch, setIsNewSearch] = useState(true);
  const router = useRef();
  const sortBy = RouterStore.state.query.sortBy || (RouterStore.state.query.q ? SORT_MATCHING : SORT_RECENT);
  const sortOrder = RouterStore.state.query.sortOrder || SORTING_ORDER_DESCENDING;
  const showEmptyMessage = props.results.store.showEmpty();
  const statsMessage = showEmptyMessage ? '' : props.statsMessage;
  const account = props.account;
  const curatedBanner = {
    color: (account && account.settings.accountCuratedBannerColor) || null,
    image: (account && account.settings.accountCuratedBannerImage) || null
  };
  const loadMoreFocusContainerRef = useRef(null);
  const fetchResults = (cursor) => props.fetchResults(createParamsFromProps(props), cursor);

  useEffect(() => {
    if (router.current !== props.router) {
      router.current = props.router;
      fetchResults(null);
      setIsNewSearch(true);
    }

    if (isNewSearch && props.results.searchSuccessful) {
      setIsNewSearch(false);
      AnalyticsActions.viewedSearchResults(props.results.count);
    }
  }, [props]);

  const renderCards = () => {
    return (
      <ResultCards
        {...props}
        onRetry={fetchResults}
        curatedBanner={curatedBanner}
        loadMoreFocusContainerRef={loadMoreFocusContainerRef}
      />
    );
  };

  const renderList = () => {
    return (
      <ResultsList
        results={props.results.store.get()}
        curatedBanner={curatedBanner}
        allowApprovedContent={props.session.allowApprovedContent}
        accountId={props.session.accountId}
        sortOrder={sortOrder}
        sortBy={sortBy}
      />
    );
  };

  const renderLoadMore = () => (
    <LoadMore
      hasMore={props.results.hasMore}
      count={props.results.items.length}
      onLoadMore={() => fetchResults(props.results.nextCursor)}
      isLoading={props.results.loading}
      focusContainerRef={loadMoreFocusContainerRef}
    />
  );

  const renderPagination = (isCardView) => {
    const pageFromQuery = parseInt(RouterStore.state.query.page, 10) || 1;
    const { totalPages, currentPage } = calculatePaginationPages(
      pageFromQuery,
      // TODO: handle initial load: CLAB-363, we should be able to remove `|| 1` after that
      props.results.store.getFilteredResultsCount() || 1,
      props.resultsPerPage
    );

    return (
      <div className={classnames({
        'wrapper-pagination-card': isCardView,
        'wrapper-pagination-list': !isCardView
      })}>
        <ResourcePagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={page => SearchActions.update({ page })}
          margin="none"
        />
      </div>
    );
  };

  const renderResults = () => {
    const isCardView = getState().localStorage.searchPage.resultsView === CARD_VIEW;
    const isPaginationEnabled = props.featureFlags.flags.resource_pagination;
    return (
      <React.Fragment>
        {isCardView ? renderCards() : renderList()}
        {isPaginationEnabled ? renderPagination(isCardView) : renderLoadMore()}
      </React.Fragment>
    );
  };

  return (
    <div>
      <SearchInfo {...props} statsMessage={statsMessage} includeToggleResultsViewButton />
      {showEmptyMessage
        ? props.emptyMessage
        : renderResults()}
    </div>
  );
};

SearchResultsWrapper.defaultProps = {
  baseParams: {}
};

SearchResultsWrapper.propTypes = {
  baseParams: PropTypes.object,
  fetchResults: PropTypes.func.isRequired,
  results: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  banner: PropTypes.node,
  emptyMessage: PropTypes.node.isRequired,
  statsMessage: PropTypes.node,
  searchPrivateObjects: PropTypes.bool,
  query: PropTypes.string,
  queryArialLabel: PropTypes.string
};

export default SearchResultsWrapper;
