import React from 'react';
import range from 'lodash/utility/range';
import ResultComponent from '../result/result';
import { getState } from '../../../store';
import ResultsError from './error';
import ResultPlaceholder from '../result/placeholder';

const numberOfPlaceholders = 16;

const ResultCards = (props) => {
  const renderPlaceHolders = () => {
    return range(numberOfPlaceholders)
      .map((key) => (
        <li key={key}>
          <ResultPlaceholder />
        </li>
      ));
  };

  const renderCards = (results, curatedBanner, isNewShareWorkflow) => {
    if (results.length === 0) {
      return null;
    }

    return results.map((learningObject) => (
      <ResultComponent
        key={learningObject.id}
        accountId={props.session.accountId}
        curatedBanner={curatedBanner}
        allowApprovedContent={props.session.allowApprovedContent}
        isNewShareWorkflow={isNewShareWorkflow}
        learningObject={learningObject}
        search={props.search}
      />
    ));
  };

  const results = props.results.store.get();
  const isInitialLoading = props.results.store.showLoading();
  const onRetry = () => props.onRetry(props.results.nextCursor);

  const isNewShareWorkflow = getState().featureFlags.flags.new_share_workflow;

  return (
    <React.Fragment>
      <ol className="card-layout" ref={props.loadMoreFocusContainerRef}>
        {
          isInitialLoading
            ? renderPlaceHolders()
            : renderCards(results, props.curatedBanner, isNewShareWorkflow)
        }
      </ol>
      {props.results.error && <ResultsError onRetryClick={onRetry} />}
    </React.Fragment>
  );
};

export default ResultCards;
